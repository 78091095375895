<template>
	<div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
		<Wrapper :heading="isTrial ? $t('global.createNewTrialAccount') : $t('global.createAccount')" center noinfo contain
			normal>
			<div class="d-flex flex-column align-items-center mb-3 px-10">
				<template v-if="isTrial && siteInfo && !siteInfo.openedForTrial">
					<v-alert color="info" dark type="info" width="100%">
						{{ $t('global.trialIsClosed') }}
					</v-alert>
					<button class="btn btn-outline-danger px-3" @click="goToRegistrationPage()">{{
						$t('global.createAccount') }}</button>
				</template>

				<v-form v-else v-model="processedIsFormValid">
					<div class="trial_over_container" v-if="leadId">
						{{ $t('global.TrialOver1') }}
						<div class="trial_over_inner">{{ $t('global.TrialOver2') }}</div>
					</div>
					<v-row>
						<v-col class="name-lastname">
							<input-field type="text" :props="{ styleType: 'outlined' }" v-model="processedFirstName"
								required :label="$t('global.firstName')" />
						</v-col>
						<v-col class="name-lastname">
							<input-field type="text" color="white" :props="{ styleType: 'outlined' }"
								v-model="processedLastName" required :label="$t('global.lastName')" />
						</v-col>
						<v-col>
							<input-field color="white" :props="{ styleType: 'outlined' }" type="email"
								v-model="processedEmail" required :label="$t('global.yourEmail')" />
							<div class="v-text-field__details" v-if="emailSuggestion" @click="updateEmail(emailSuggestion)">
								<div class="v-messages theme--light success--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message">{{ $t('global.didYouMean') }} {{
											emailSuggestion }}?</div>
									</div>
								</div>
							</div>
							<div class="v-text-field__details" v-else-if="emailSuggestion === $t('global.invalidEmail')">
								<div class="v-messages theme--light error--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message">{{ emailSuggestion }}</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col>
							<input-field color="white" :props="{ styleType: 'outlined' }" type="special-text"
								v-model="processedUsername" required :label="$t('global.username')" />

							<span v-if="!isUsernameValid" class="red-signature">{{ $t('global.fieldMustBe')
							}}</span>
						</v-col>
						<v-col>
							<vue-tel-input mode="international" @input="phoneFormatValidate" v-model="processedPhone"
								:input-options="{ placeholder: $t('global.phoneNumber'), styleClasses: 'phone-validator' }"
								:default-country="clientIpCountry.name">
								<template slot="arrow-icon"> <span class="vti__dropdown-arrow">&nbsp;▼</span></template>
							</vue-tel-input>
							<div class="v-text-field__details" v-if="!isPhoneFormatValid && _.size(phone) > 0">
								<div class="v-messages theme--light error--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message">{{ $t('global.invalidPhone') }}</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col>
							<input-field type="select" v-model="processedCountry" :value="processedCountry"
								:label="$t('global.selectCountry')" :props="{ items: countries, styleType: 'outlined' }" />
						</v-col>
						<v-col v-if="states.length > 0">
							<input-field type="select" v-model="processedCountryState" :value="processedCountryState"
								:label="$t('global.selectCountryState')"
								:props="{ items: states, styleType: 'outlined' }" />
						</v-col>
						<v-col>
							<input-field type="special-text" v-model="processedReff" :props="{ styleType: 'outlined' }"
								:label="$t('global.reff')" />
						</v-col>
						<v-col>
							<input-field type="special-text" v-model="processedPromoCode" :props="{ styleType: 'outlined' }"
								:label="$t('global.referral')" />
						</v-col>
						<v-col>
							<!-- <input-field type="special-text" v-model="referralCode" :props="{ styleType: 'outlined' }" :label="$t('global.referralCode')" /> -->
						</v-col>
					</v-row>
					<!-- <v-row v-if="!isTrial && !mSite && !hideWarning">
							<v-col>
								<p style="color: #f28f26; border-color: white; border-width: 1px; border-style: solid; padding: 10px;">
									{{ $t('global.createNormalAccountNoTrial') }}<br />
									{{ $t('global.ifWantTrialClick') }}
									<label @click="isTrial = true" style="cursor: pointer;font-weight: 800;">{{ $t('global.here') }}</label> {{ $t('global.instead') }}
								</p>
							</v-col>
						</v-row> -->
				</v-form>
				<recaptcha />
				<div class="register-buttons-container">
					<v-btn
						:class="!isUsernameValid || !country || !isFormValid || (isTrial && !isPhoneFormatValid) ? 'btns disable-btn' : 'btns submit-btn'"
						@click="submit">
						{{ $t('global.continue') }}
					</v-btn>
					<v-btn color="white" class="btns" @click="cancel">
						{{ $t('global.cancel') }}
					</v-btn>
				</div>
			</div>
		</Wrapper>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: [
		'isTrial',
		'goToRegistrationPage',
		'isFormValid',
		'updateIsFormValid',
		'leadId',
		'firstName',
		'updateFirstName',
		'lastName',
		'updateLastName',
		'email',
		'updateEmail',
		'emailSuggestion',
		'username',
		'updateUsername',
		'isUsernameValid',
		'phone',
		'updatePhone',
		'phoneFormatValidate',
		'clientIpCountry',
		'isPhoneFormatValid',
		'country',
		'updateCountry',
		'countryState',
		'updateCountryState',
		'states',
		'countries',
		'reff',
		'updateReff',
		'promoCode',
		'updatePromoCode',
		'submit',
		'cancel',
	],
	components: {
		Wrapper: () => import('@/components/Wrapper.vue'),
		Recaptcha: () => import('@/components/Recaptcha.vue')
	},
	computed: {
		...mapGetters(['siteInfo']),
		processedFirstName: {
			get() {
				return this.firstName;
			},
			set(value) {
				this.updateFirstName(value);
			}
		},
		processedLastName: {
			get() {
				return this.lastName;
			},
			set(value) {
				this.updateLastName(value);
			}
		},
		processedEmail: {
			get() {
				return this.email;
			},
			set(value) {
				this.updateEmail(value);
			}
		},
		processedUsername: {
			get() {
				return this.username;
			},
			set(value) {
				this.updateUsername(value);
			}
		},
		processedPhone: {
			get() {
				return this.phone;
			},
			set(value) {
				this.updatePhone(value);
			}
		},
		processedCountry: {
			get() {
				return this.country;
			},
			set(value) {
				this.updateCountry(value);
			}
		},
		processedCountryState: {
			get() {
				return this.countryState;
			},
			set(value) {
				this.updateCountryState(value);
			}
		},
		processedReff: {
			get() {
				return this.reff;
			},
			set(value) {
				this.updateReff(value);
			}
		},
		processedPromoCode: {
			get() {
				return this.promoCode;
			},
			set(value) {
				this.updatePromoCode(value);
			}
		},
		processedIsFormValid: {
			get() {
				return this.isFormValid;
			},
			set(value) {
				this.updateIsFormValid(value);
			}
		}
	},
};
</script>

<style>
.trial_over_container {
	color: #fff;
	position: relative;
	top: -116px;
	height: 0;
	text-align: center;
}

.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.Components-field-2K_U.Components-required-3FxJ.Components-focused-32y0 input {
	color: white !important;
}

.v-input.v-input--has-state.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.error--text.Components-field-2K_U.Components-required-3FxJ.Components-focused-32y0 input {
	color: white !important;
}

.theme--light.v-input,
.theme--light.v-label,
.theme--light.v-input textarea,
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
	color: rgba(255, 255, 255, 0.486) !important;
	border: none;
	background-color: rgb(39, 51, 63);
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
	border-color: rgba(255, 255, 255, 0.336);
}

.v-text-field--outlined.v-input--dense .v-label--active {
	left: -13px;
	-webkit-transform: translateY(-328px) scale(.75);
	transform: translateY(-28px) scale(.75);
	background-color: transparent;
}

.v-text-field--outlined.v-input--has-state fieldset,
.v-text-field--outlined.v-input--is-focused fieldset {
	border: none;
}

.btn.btn-danger.v-btn--disabled {
	color: white !important;
}

.phone-validator {
	color: white;
}

.default-class {
	color: black !important;
	min-width: 180px !important;
}

.vue-tel-input {
	min-width: 180px !important;
	padding: 5px 0;
	background-color: rgb(39, 51, 63);
	border: none;
}

.default-class:has(.trial_over_container) {
	padding-top: 4em !important;
}

.register-buttons-container {
	display: flex;
	margin-top: 1em;
	width: 100%;
	justify-content: space-between;
}
</style>

<style scoped>
.default-class {
	max-width: 550px;
}

.name-lastname {
	min-width: 100px;
}

.btns {
	width: 45%;
	font-size: 18px;
	color: #5c6167 !important;
	border: 2px solid #5c6167;
	border-radius: 7px;
	padding: 5px 0;
}

.submit-btn {
	background-color: #16a637;
	border: #16a637;
	color: white !important;
}

.disable-btn {
	pointer-events: none;
	color: rgb(255, 255, 255, 0.1) !important;
	border: 2px solid rgb(255, 255, 255, 0.1) !important;
}

.v-btn:not(.v-btn--round).v-size--default {
	height: auto !important;
	padding: 10px 0 !important;
}

.theme--light.v-input {
	min-width: 166px;
}

.red-signature {
	font-size: 14px;
	color: #f15252;
}
</style>
